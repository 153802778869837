import React, {Component} from 'react'
import {navigate} from "gatsby";
import {Button, Container, Input, Form, FormFeedback, Label, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactImage from "../components/ContactImage";
import FormGroup from "reactstrap/es/FormGroup";

class Kontakt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            type: "Wahlarzt",
            fach: "allgemeinmedizin",
            message: "",
            validate: {
                nameState: "",
                emailState: "",
            }
        };
    }

    validateName(e) {
        const {name, validate} = this.state;
        if (name && name.length > 2) {
            validate.nameState = 'has-success'
        } else {
            validate.nameState = 'has-danger'
        }
        this.setState({validate})
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {email, validate} = this.state
        if (emailRex.test(email)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({validate})
    }

    handleChange = async (event) => {
        const {target} = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value,
        });
    }

    submitForm(e) {
        this.validateName(e);
        this.validateEmail(e);
        const {validate} = this.state;
        if (validate.nameState === "has-success" && validate.emailState === "has-success") {
            const {name, email, phone, type, fach, message} = this.state;
            fetch('https://medoboard-app.appspot.com/mail/v1/sendMail', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify({
                    name: name,
                    from: email,
                    phone: phone,
                    type: type,
                    fach: fach,
                    message: message
                })
            }).then((res) => {
                navigate("/success-mail");
            }).catch((error) => {
                console.log(error);
            });
        }
        e.preventDefault();
    }

    render() {
        const {name, email, phone, type, fach, message} = this.state;
        return (
            <Layout>
                <SEO googleTitle="Medoboard - optimiert Ihre Ordination"
                     googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
                     fbUrl="https://medoboard.com/kontakt"
                     fbTitle="Medoboard - optimiert Ihre Ordination"
                     fbDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten."
                     keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
                <Container>
                    <Row className="carousel">
                        <Col md="12">
                            <ContactImage/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h2 className="featurette-heading">Kontakt</h2>
                            <h4 className="text-muted">Wir freuen uns auf Ihre Nachricht und antworten so schnell wie
                                möglich!</h4>
                            <hr className="featurette-divider"/>
                            <Row>
                                <Col md={{size: 6, offset: 3}}>
                                    <Form onSubmit={(e) => this.submitForm(e)}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="nameInput">Name</Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        id="nameInput"
                                                        placeholder="Name"
                                                        value={name}
                                                        valid={this.state.validate.nameState === 'has-success'}
                                                        invalid={this.state.validate.nameState === 'has-danger'}
                                                        onChange={(e) => {
                                                            this.validateName(e)
                                                            this.handleChange(e)
                                                        }}/>
                                                    <FormFeedback valid>
                                                        Vielen Dank für ihren Namen.
                                                    </FormFeedback>
                                                    <FormFeedback>
                                                        Bitte geben Sie ihren Namen an.
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="emailInput">Email</Label>
                                                    <Input type="email"
                                                           name="email"
                                                           id="emailInput"
                                                           placeholder="dr.max.mustermann@gmail.com"
                                                           value={email}
                                                           valid={this.state.validate.emailState === 'has-success'}
                                                           invalid={this.state.validate.emailState === 'has-danger'}
                                                           onChange={(e) => {
                                                               this.validateEmail(e)
                                                               this.handleChange(e)
                                                           }}/>
                                                    <FormFeedback valid>
                                                        Vielen Dank für ihre Email.
                                                    </FormFeedback>
                                                    <FormFeedback>
                                                        Das sieht leider nicht nach einer validen Email aus. Bitte
                                                        korrigieren Sie ihre Eingaben!
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="phoneInput">Telefonnummer</Label>
                                                    <Input type="text"
                                                           name="phone"
                                                           id="phoneInput"
                                                           placeholder="+43 664 1234 5678"
                                                           value={phone}
                                                           onChange={(e) => this.handleChange(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="typeInput">Arzttyp</Label>
                                                    <Input type="select"
                                                           name="type"
                                                           id="typeInput"
                                                           value={type}
                                                           onChange={(e) => this.handleChange(e)}>
                                                        <option>Wahlarzt</option>
                                                        <option>Kassenarzt</option>
                                                        <option>Spitalsarzt</option>
                                                        <option>Ich habe nur Interesse</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="fachInput">Fachrichtung</Label>
                                                    <Input type="select"
                                                           name="fach"
                                                           id="fachInput"
                                                           value={fach}
                                                           onChange={(e) => this.handleChange(e)}>
                                                        <option value="Allgemeinmedizin">Allgemeinmedizin</option>
                                                        <option value="Anästhesiologie">Anästhesiologie</option>
                                                        <option value="Augenheilkunde">Augenheilkunde</option>
                                                        <option value="Blutgruppenserologie">Blutgruppenserologie
                                                        </option>
                                                        <option value="Chirurgie">Chirurgie</option>
                                                        <option value="Dermatologie">Dermatologie</option>
                                                        <option value="Gynäkologie">Gynäkologie</option>
                                                        <option value="Hals-, Nasen- und Ohrenkrankheiten">Hals-, Nasen-
                                                            und
                                                            Ohrenkrankheiten
                                                        </option>
                                                        <option value="Immunologie">Immunologie</option>
                                                        <option value="Innere Medizin">Innere Medizin</option>
                                                        <option value="Kinderchirurgie">Kinderchirurgie</option>
                                                        <option value="Kinder- und Jugendheilkunde">Kinder- und
                                                            Jugendheilkunde
                                                        </option>
                                                        <option value="Kinder- und Jugendpsychiatrie">Kinder- und
                                                            Jugendpsychiatrie
                                                        </option>
                                                        <option value="Lungenkrankheiten">Lungenkrankheiten</option>
                                                        <option value="Mund-, Kiefer- und Gesichtschirurgie">Mund-,
                                                            Kiefer-
                                                            und
                                                            Gesichtschirurgie
                                                        </option>
                                                        <option value="Neurochirurgie">Neurochirurgie</option>
                                                        <option
                                                            value="Neurologie (Neurologie &amp; Psychiatrie)">Neurologie
                                                            (Neurologie &amp; Psychiatrie)
                                                        </option>
                                                        <option
                                                            value="Orthopädie und orthopädische Chirurgie">Orthopädie
                                                            und
                                                            orthopädische Chirurgie
                                                        </option>
                                                        <option value="Physikalische Medizin">Physikalische Medizin
                                                        </option>
                                                        <option value="Plastische Chirurgie">Plastische Chirurgie
                                                        </option>
                                                        <option
                                                            value="Psychiatrie (Psychiatrie &amp; Neurologie)">Psychiatrie
                                                            (Psychiatrie &amp; Neurologie)
                                                        </option>
                                                        <option value="Radiologie">Radiologie</option>
                                                        <option value="Unfallchirurgie">Unfallchirurgie</option>
                                                        <option value="Urologie">Urologie</option>
                                                        <option value="Zahn-, Mund- und Kieferheilunde">Zahn-, Mund- und
                                                            Kieferheilunde
                                                        </option>
                                                        <option value="Andere Fachrichtung">Andere Fachrichtung</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="messageText">Ihre Nachricht</Label>
                                                    <Input type="textarea"
                                                           name="message"
                                                           id="messageText"
                                                           value={message}
                                                           onChange={(e) => this.handleChange(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="text-right">
                                                    <Button color="secondary" className="mr-3">Abbrechen</Button>
                                                    <Button type="submit" color="primary"
                                                            className="text-right">Senden</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        )
    }
}

export default Kontakt;